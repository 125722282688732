/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/joy';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { UpgradeApi } from '../apis/upgrade.api';
import { UpgradeContext } from '../context/upgrade.context';
import ModalConfirmation from './ModalConfirmation.component';
import { MemberApi } from '../apis/member.api';
import { useFetch } from '../hooks/useFetch';

const MemberCard = ({ sx }) => {
  const user = JSON.parse(sessionStorage.getItem('user'));
  const navigate = useNavigate();
  const { fetchUpgrade, useUpgrade } = useContext(UpgradeContext);

  const [referralNotExist, setReferralNotExist] = useState(false);
  const [disabledUpgrade, setDisabledUpgrade] = useState(true)

  const { data: permission } = useFetch(MemberApi.permission({ type: `mog_upgrade` }), null)

  const handleUpgrade = async () => {
    try {
      await UpgradeApi.checkReferral();
      navigate('/registration/upgrade/create');
    } catch (err) {
      setReferralNotExist(true);
    }
  };

  React.useEffect(() => {
    if (permission?.permission) {
      setDisabledUpgrade(false)
    }
  }, [permission])

  useEffect(() => {
    fetchUpgrade()
  }, [])

  return (
    <Box sx={sx}>
      <Box
        sx={{
          borderRadius: user?.type === 'AOG' ? '8px' : '8px 8px 0 0',
          background: 'linear-gradient(90deg, #000000 0%, #485563 100%)',
          padding: '1rem 1rem',
        }}
      >
        <Typography
          textAlign='center'
          sx={{
            fontWeight: 'bold',
            background:
              user?.type === 'AOG'
                ? 'linear-gradient(90deg, #F83600 0%, #F9D423 100%)'
                : 'linear-gradient(90deg, #6A85B6 0%, #BAC8E0 100%)',
            backgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          {user?.type === 'AOG' ? 'AGENT OF GOLD' : 'MEMBER OF GOLD'}
        </Typography>
        <Typography
          textAlign='center'
          sx={{
            color: '#FFF',
            marginTop: '0.8rem',
            maxWidth: '222px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'block',
          }}
        >
          {user?.name}
        </Typography>
        <Typography
          textAlign='center'
          sx={{ color: '#BFC0C3', fontWeight: 'bold' }}
        >
          {user?.no_id}
        </Typography>
      </Box>
      {user?.type === 'MOG' && (
        <Box
          sx={{
            padding: '0.5rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'linear-gradient(90deg, #F83600 0%, #F9D423 100%)',
            borderRadius: '0 0 8px 8px',
            cursor: disabledUpgrade ? 'not-allowed' : 'pointer',
            borderTop: '1px solid #fff',
          }}
        >
          {useUpgrade.canUpgrade && (
            <Typography fontSize='14px' sx={{ color: '#fff' }}>
              Upgrade ke{' '}
              <Typography
                fontWeight='bold'
                sx={{ marginRight: '5px', cursor: disabledUpgrade ? 'not-allowed' : 'pointer', }}
                onClick={disabledUpgrade ? undefined : handleUpgrade}
              >
                AGENT OF GOLD
              </Typography>
            </Typography>
          )}
          {useUpgrade.processUpgrade && (
            <Typography fontSize='14px' sx={{ color: '#fff' }}>
              Upgrade:{' '}
              <Typography
                fontWeight='bold'
                sx={{ marginRight: '5px', cursor: 'pointer' }}
                onClick={() =>
                  navigate(
                    `/registration/upgrade/${useUpgrade.invoice_id}`
                  )
                }
              >
                {useUpgrade.status_str}
              </Typography>
            </Typography>
          )}
          <ChevronRightIcon style={{ color: '#fff' }} />
        </Box>
      )}

      <ModalConfirmation
        title='ID AOG/MOG'
        message='ID AOG/MOG pereferal anda tidak ditemukan.
        Apakah anda yakin untuk upgrade ke AOG?
        
        Silakan hubungi Customer Service
        (082211967626) untuk upgrade ID AOG/MOG'
        open={referralNotExist}
        btnText='Lanjutkan'
        onClose={() => setReferralNotExist(false)}
        handleSubmit={() => {
          navigate('/registration/upgrade/create');
          setReferralNotExist(false);
        }}
      />
    </Box>
  );
};

export default MemberCard;
