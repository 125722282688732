import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Grid,
  Typography,
  FormControl,
  FormLabel,
  Input,
  IconButton,
  Divider,
  FormHelperText,
  TabList,
  Tabs,
  Autocomplete,
} from '@mui/joy';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import EditIcon from '@mui/icons-material/Edit';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { useFetch } from '../../hooks/useFetch';
import { BuybackApi } from '../../apis/buyback.api';
import { SnackbarContext } from '../../context/snackbar.context';
import Title from '../../components/Title.component';
import FormProduct from '../../components/FormProduct.component';
import CardDetailOrder from '../../components/CardDetailOrder.component';
import ModalConfirmation from '../../components/ModalConfirmation.component';
import CustomTab from '../../components/CustomTab.component';
import CardTypeBuyback from '../../components/CardTypeBuyback.component';
import { useDebouncedCallback } from 'use-debounce';

dayjs.extend(utc);

const validationSchema = yup.object().shape({
  concerned_name: yup.string().required('Data harus diisi!'),
  concerned_msisdn: yup.string().required('Data harus diisi!'),
  concerned_address: yup.string().required('Data harus diisi!'),
});

const BuybackCreate = () => {
  const navigate = useNavigate();
  const { setSnackbar } = React.useContext(SnackbarContext);

  const [type, setType] = React.useState('receive');
  const [customer, setCustomer] = React.useState('public');
  const [time, setTime] = React.useState(dayjs(new Date()));
  const [selectedProduct, setSelectedProduct] = React.useState({});
  const [openTime, setOpenTime] = React.useState(false);
  const [confirmation, setConfirmation] = React.useState(false);

  const [searchDestination, setSearchDestination] = React.useState('');
  const [selectedDestination, setSelectedDestination] = React.useState(null)

  const { data: listProducts } = useFetch(
    BuybackApi.product({
      price_date: dayjs(time).utc().format('YYYY-MM-DD HH:mm:ss'),
    })
  );
  const { data: listConcerned } = useFetch(BuybackApi.concerned({ type }));
  const { data: listDestination, loading: loadingDestination } = useFetch(
    BuybackApi.destination({ concerned_type: customer, search: searchDestination || '' })
  );

  const handleDebounceDestination = useDebouncedCallback((value) => {
    setSearchDestination(value)
  }, 500)

  const handleDestination = (_, value) => {
    setSelectedDestination(value)
    formik.values.concerned_name = value?.name || null;
    formik.values.concerned_msisdn = value?.msisdn || null;
    formik.values.concerned_address = value?.address || null;
    formik.values.concerned_id = value?.concerned_id || null;
  };

  const formik = useFormik({
    initialValues: {
      concerned_name: '',
      concerned_msisdn: '',
      concerned_address: '',
      concerned_id: '',
    },
    validationSchema,
    onSubmit: () => setConfirmation(true),
  });

  const handleCreate = async () => {
    try {
      const data = {
        concerned_name: formik.values.concerned_name,
        concerned_msisdn: formik.values.concerned_msisdn,
        concerned_address: formik.values.concerned_address,
        concerned_id: formik.values.concerned_id,
        concerned_type: customer,
        invoice_date: dayjs(time).utc().format('YYYY-MM-DD HH:mm:ss'),
        product: Object.values(selectedProduct),
        type: type,
      };

      const response = await BuybackApi.create(data);
      navigate(`/report/buyback/${response.invoice_id}`);
      setSnackbar({ open: true, type: 'success', message: response.msg_str });
    } catch (err) {
      console.log(err.response.data);
      setSnackbar({
        open: true,
        type: 'error',
        message: err.response.data.message || err.response.data.msg_str,
      });
    }
  };

  return (
    <div>
      <Title text='Buyback' Icon={AutorenewIcon} />
      <Grid container spacing={2} marginTop='1rem'>
        <Grid xs={12} md={7}>
          <Typography level='body2'>Tanggal dan Waktu Transaksi</Typography>
          <Box display='flex' alignItems='center'>
            <MobileDateTimePicker
              open={openTime}
              sx={{ display: 'none' }}
              value={time}
              onChange={(val) => setTime(val)}
              onAccept={() => setOpenTime(false)}
            />
            <Typography marginRight='15px' level='h4'>
              {dayjs(time).format('dddd DD MMMM YYYY, HH:mm WIB')}
            </Typography>
            <Button
              variant='outlined'
              size='sm'
              startDecorator={<EditIcon />}
              onClick={() => setOpenTime(true)}
            >
              Ubah
            </Button>
          </Box>
          <Box marginTop='1.5rem'>
            <Grid container spacing={1}>
              <Grid xs={6}>
                <CardTypeBuyback
                  selected={type === 'receive'}
                  onClick={() => setType('receive')}
                  title='Terima Buyback'
                  content='Pembelian kembali produk EOA Gold dari Customer Umum atau MOG.'
                  bottomContent={
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#FAFAFA',
                        color: '#52C41A',
                        borderRadius: '0 0 10px 10px',
                        padding: '0.4rem',
                      }}
                    >
                      <ArrowDownwardIcon sx={{ marginRight: '5px' }} />
                      <Typography level='body3' textAlign='center'>
                        IN - Menambah Stok Produk
                      </Typography>
                    </Box>
                  }
                />
              </Grid>
              <Grid xs={6}>
                <CardTypeBuyback
                  selected={type === 'submit'}
                  onClick={() => setType('submit')}
                  title='Transaksi Buyback'
                  content='Penjualan kembali produk EOA Gold ke Customer AOG atau EGS.'
                  bottomContent={
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#FAFAFA',
                        color: '#FF4D4F',
                        borderRadius: '0 0 10px 10px',
                        padding: '0.4rem',
                      }}
                    >
                      <ArrowUpwardIcon sx={{ marginRight: '5px' }} />
                      <Typography level='body3' textAlign='center'>
                        OUT - Mengurangi Stok Produk
                      </Typography>
                    </Box>
                  }
                />
              </Grid>
            </Grid>
          </Box>
          <Box marginTop='1rem' marginBottom='0.5rem'>
            <FormLabel>Tipe Customer</FormLabel>
            <Tabs
              value={customer}
              onChange={(_, value) => setCustomer(value)}
              size='sm'
            >
              <TabList>
                {listConcerned?.map((item) => (
                  <CustomTab
                    key={item.concerned_type}
                    value={item.concerned_type}
                    selected={customer}
                  >
                    {item.name}
                  </CustomTab>
                ))}
              </TabList>
            </Tabs>
          </Box>
          {customer === 'public' && (
            <Box>
              <Box marginTop='1rem'>
                <Grid container spacing={1}>
                  <Grid xs={12} md={6}>
                    <FormControl>
                      <FormLabel>Nama Lengkap</FormLabel>
                      <Input
                        name='concerned_name'
                        value={formik.values.concerned_name}
                        onChange={formik.handleChange}
                        fullWidth
                        placeholder='Masukkan nama lengkap'
                      />
                      <FormHelperText>
                        {formik.errors.concerned_name}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <FormControl>
                      <FormLabel>No Telepon</FormLabel>
                      <Input
                        name='concerned_msisdn'
                        value={formik.values.concerned_msisdn}
                        onChange={formik.handleChange}
                        fullWidth
                        placeholder='Masukkan no telepon'
                      />
                      <FormHelperText>
                        {formik.errors.concerned_msisdn}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <Box marginTop='0.5rem'>
                <FormControl>
                  <FormLabel>Alamat Lengkap</FormLabel>
                  <Input
                    name='concerned_address'
                    value={formik.values.concerned_address}
                    onChange={formik.handleChange}
                    fullWidth
                    placeholder='Tulis alamat lengkap'
                  />
                  <FormHelperText>
                    {formik.errors.concerned_address}
                  </FormHelperText>
                </FormControl>
              </Box>
            </Box>
          )}
          {customer !== 'public' && (
            <Box marginTop='1.5rem'>
              <FormControl>
                <FormLabel>Pilih Customer</FormLabel>
                <Autocomplete
                  placeholder='Silahkan pilih customer'
                  getOptionLabel={(option) =>
                    `${option.name} ${
                      option.member_id ? `- ${option.member_id}` : ''
                    }`
                  }
                  onInputChange={(e) => handleDebounceDestination(e.target?.value || '')}
                  loading={loadingDestination}
                  options={listDestination}
                  value={selectedDestination}
                  onChange={handleDestination}
                />
              </FormControl>
            </Box>
          )}
          <Box display='flex' alignItems='center' marginTop='1.5rem'>
            <IconButton color='primary'>
              <ShoppingBasketIcon />
            </IconButton>
            <Typography level='h6'>List Produk</Typography>
          </Box>
          <Box marginTop='0.7rem'>
            {listProducts?.map((item) => (
              <div key={item.id_product}>
                <FormProduct
                  data={item}
                  selectedProduct={selectedProduct}
                  setSelectedProduct={setSelectedProduct}
                  keyPrice='buyback_price'
                  validateStock={false}
                />
                <Divider sx={{ marginY: '0.7rem' }} />
              </div>
            ))}
          </Box>
        </Grid>
        <Grid xs={12} md={5}>
          <CardDetailOrder
            selectedProduct={selectedProduct}
            handleSubmit={formik.handleSubmit}
            textButton={
              type === 'receive' ? 'Terima Buyback' : 'Transaksi Buyback'
            }
            keyPrice='buyback_price'
          />
        </Grid>
      </Grid>

      <ModalConfirmation
        title='Simpan Penjualan'
        message='Yakin ingin menyimpan penerimaan buyback EOA Gold?'
        open={confirmation}
        onClose={() => setConfirmation(false)}
        handleSubmit={() => {
          handleCreate();
          setConfirmation(false);
        }}
      />
    </div>
  );
};

export default BuybackCreate;
